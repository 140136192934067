import React from 'react';
import get from 'lodash/get';
import { GridRow, Picture } from '@src/theme/Global.styled';
import { Text } from '@src/components/Text';
import { Card, Cards } from '@src/components/Cards';
import { IconLink } from '@src/components/Buttons';
import {
  CardContent,
  StyledContentLink,
} from '@src/components/Cards/Cards.styles';
import Link from 'next/link';
import { getDateTimeFormat } from '@src/utils/getTimeFormat';
import { IETLatestNews } from '@src/models/IETLatestNews';
import Image from 'next/image';
import styles from './styles.module.scss';

interface UpdateProps {
  block: IETLatestNews;
}

const LatestUpdate = (props: UpdateProps) => {
  const { block } = props;
  const fields = get(block, 'fields', {});
  return (
    <GridRow
      data-block-type={get(block, 'system.contentType')}
      className={styles.latestUpdateContainer}
    >
      <h2>{fields.title}</h2>

      <Cards columns={2}>
        {fields?.blocks?.map((item, itemIndex) => (
          <Card type="article" key={itemIndex}>
            <Link href={get(item, 'system.urlSegment', '#') || ''} passHref>
              <StyledContentLink>
                <Picture height={300}>
                  <div className={styles.imageBox}>
                    <Image
                      src={item?.fields?.openGraphImage?.fields?.umbracoFile || '/niels-stensen-bg.png'}
                      width={595}
                      height={300}
                      alt={item?.fields?.pageTitle ?? ''}
                      objectFit="cover"
                      loading="lazy"
                    />
                  </div>
                </Picture>
                <CardContent>
                  <h3>{get(item, 'fields.pageTitle')}</h3>
                  <Text size="small">
                    {getDateTimeFormat(
                      new Date(
                        item?.fields?.startDate ?? item?.system?.editedAt ?? '/niels-stensen-bg.png',
                      ),
                      'DD/MM/YYYY',
                    )}
                  </Text>
                  <Text>{get(item, 'fields.metaDescription', '')}</Text>
                </CardContent>
              </StyledContentLink>
            </Link>
          </Card>
        ))}
      </Cards>
      <IconLink
        type="page"
        href={get(fields, 'links[0].url', '#') || '#'}
        target={get(fields, 'links[0].target', '_self') || '_self'}
      >
        {get(fields, 'links[0].name', '')}
      </IconLink>
    </GridRow>
  );
};

export default LatestUpdate;
